<template>



    <div 
      ref="rootEl"
      :data-id="props.week.id"
      :data-week-id="props.week.id"
      :class="'day_item day_faza_' + week.faza + '  post_day_' + week.id + (weekActive?.id == week.id ? ' active ' : '')"                                               
    >

      <!-- <WeekwideViewHarvestTitle
        v-if="props.week.item_harvest"
        :harvest-name="props.week.item_harvest?.item_review_seed.item_product.name"
        :harvest-brand="props.week.item_harvest?.item_review_seed.item_brand.name"
        />  -->


      <div class="week__header">
        <WeekwideViewWeekName 
          :days="props.week.days"
          :faza="props.week.faza"
          :week-id="props.week.id"
        />
        <WeekwideViewActionsEdit 
          v-if="useAuth().isOwner(props.diary.item_user.id)"
          :week="props.week" 
          :owner="props.diary.item_user.id" 
          :diary-link="props.diary.link" 
          :is-harvest="props.week.item_harvest ? true : false"
          @remove-week="handleRemoveWeek"
          @share-week="emits('shareWeek')"
        />
        <div v-else class="date">
          {{ $dayjs(props.week.add_date).fromNow() }}
        </div>

      </div>
 


      <WeekwideViewHarvest
        v-if="props.week.faza === WeekFaza.Harvest"
        :week-harvest="props.week.item_harvest"
        :week-props="props.week.props"
        :weeks="props.diary.items_week"
      /> 

      <WeekwideViewGallerySlider 
        :week="week" 
        :diary="diary"
      />

 
      <WeekwideViewCommentSetup
        v-if="props.week.faza != WeekFaza.Harvest"
        :setup="props.week.props"    
        @click="setupShow = true" 
      /> 

      <WeekwideViewNutrientsSetup
        :nutrients="props.week.item_germination?.items_nutrient || week.item_vegetation?.items_nutrient || week.item_flowering?.items_nutrient"
        @click="nutrientsShow = true"
      /> 

      <!-- <WeekwideViewComment 
        :week="week" 
        :weeks="diary.items_week"
        :user="diary.item_user"
        :comments="props.comments"
        @click="commentShow = true"
        /> -->


      <WeekwideViewAuthorComment
        :week="props.week"
        :item-user="diary.item_user"
        :week-id="props.week.id"
        :comments="props.comments"        
      />


      <WeekwideViewHarvestEquipReviews
        v-if="props.week.item_harvest?.items_review?.length"
        :data="props.week.item_harvest.items_review"
      />


      
      <WeekwideViewGermMethod 
        :item-method="props.week.item_germination?.germ_method" 
      />

      <WeekwideViewMethods 
        :items-method="props.week.item_vegetation?.items_method || props.week.item_flowering?.items_method" 
      />


      <div 
        v-if="problems?.length"
        class="dot-ttl"
      >
          {{ $t('week_view_grow_questions') }}
      </div>
  
      <DiaryViewQuestionsList
        :list="problems"
      />


      <div 
        class="cpm" 
        v-if="existsBanner"
      ></div>
 

    </div>



    <!--  -->

    <ClientOnly>
      <Teleport to="#teleport"> 

        <CommentWidgetFlowWeek
          v-if="commentShow"
          :title="$t('journal_view_post_comments')"
          :type="'diary'"
          :content="props.week.id"
          :week-text="props.week.text"
          :week-author="props.diary.item_user"
          :show-by-weeks="props.week.cnt_comments ? true : false"
          @close="commentShow = false"
        />              

            
        <WeekwideViewSetupFlow
          v-if="setupShow"
          :title="'Setup'"
          :week="props.week"
          @close="setupShow = false"
        />

            
        <WeekwideViewReviewFlow
          v-if="reviewShow"
          :title="'Review'"
          :week="props.week"
          @close="reviewShow = false"
        />


        <WeekwideViewNutrientsFlow
          v-if="nutrientsShow"
          :title="'Nutrients'"
          :week-nutrients="props.week.item_germination?.items_nutrient || props.week.item_vegetation?.items_nutrient || props.week.item_flowering?.items_nutrient"
          @close="nutrientsShow = false"
        />
          
      </Teleport>
    </ClientOnly>
 
  
</template>

<script setup lang="ts"> 

import type { Diary, Week } from '@/types/diary'
import { WeekFaza } from '@/types/diary'

interface PageProps {
  diary: Diary,
  week: Week,
  weekActive: Week | null,
  problems: any,
  comments: any
  num: number
}
const props = defineProps<PageProps>()

const rootEl = ref<HTMLElement | null>(null)
const commentShow = ref(false);
const commentShowWeekId = ref(null);
const nutrientsShow = ref(false);
const setupShow = ref(false);
const reviewShow = ref(false);

const emits = defineEmits(['removeWeek', 'updateWeek', 'shareWeek'])

defineExpose({
  rootEl
})


const handleRemoveWeek = (week: Week) => {
  emits('removeWeek', week)
}

const existsBanner = computed(() => {
  if(useAdvNo().value) return false
  if (useDisabledAdv().value) return false
  if(useAuth().isOwner(props.diary.item_user.id)) return false
  return true;
  // return props.num % 2 === 0
})

const toggleComment = function(data) {
  commentShow.value = !commentShow.value;
  if(data){ 
    console.log('data.weekId', data.weekId);
    commentShowWeekId.value = data.weekId;
    commentShow.value = true;
  }
}
provide('actionsToggleCommentEvent', toggleComment);

</script>

<style scoped>

.week__header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cpm{
  margin: 4rem auto 2rem auto;
  min-height: 250px;
}

.date{
  color: gray;
}

</style>
